<template>
  <div class="zdd-invite-public">
    <div class="zdd-invite-public-header-bg">
      <img src="@/assets/imgs/zdd/zdd_invite_header_bg.png" alt="header_bg" />
    </div>
    <div class="zdd-invite-public-main">
      <section class="zdd-invite-public-main-container">
        <div
          class="zdd-invite-public-main-container-item"
          v-for="item in containerOptions1"
          :key="item.title"
        >
          <div class="zdd-invite-public-main-container-item-title">
            <div class="zdd-invite-public-main-container-item-title-main">
              {{ item.title }}
            </div>
            <div
              class="zdd-invite-public-main-container-item-title-sub"
              v-if="item.subTitle"
            >
              {{ item.subTitle }}
            </div>
          </div>
          <div class="zdd-invite-public-main-container-item-body">
            {{ item.content }}
          </div>
        </div>
      </section>
      <div class="zdd-invite-public-main-desc blue">
        在1.0版本，我们推出了6个证书
      </div>
      <section
        class="zdd-invite-public-main-container step"
        v-for="item in containerOptions2"
        :key="item.order"
      >
        <div class="zdd-invite-public-main-container-title">
          <span class="zdd-invite-public-main-container-title-order">{{
            item.order
          }}</span>
          <span class="zdd-invite-public-main-container-title-text">{{
            item.title
          }}</span>
        </div>
        <div class="zdd-invite-public-main-container-cert-list">
          <img :src="item.img1" alt="img" />
          <img :src="item.img2" alt="img" />
        </div>
        <div class="zdd-invite-public-main-container-cert-desc">
          <img :src="item.img3" alt="img" />
        </div>
      </section>

      <div class="zdd-invite-public-main-desc black">
        <p class="zdd-invite-public-main-desc-item">
          即便公司没有绩效考核也可以直接颁发给表现好的员工，不需要配合复杂的管理制度就能完成，简单有效。
        </p>
        <p class="zdd-invite-public-main-desc-item">
          奖金、期权激励、优秀员工等更多证书敬请期待~
        </p>
      </div>

      <section class="zdd-invite-public-main-container feature">
        <div class="zdd-invite-public-main-container-title">
          <div class="zdd-invite-public-main-container-title-main">
            {{ feature.title }}
          </div>
          <div class="zdd-invite-public-main-container-title-desc">
            {{ feature.desc }}
          </div>
        </div>
        <div class="zdd-invite-public-main-container-feature-list">
          <div
            class="zdd-invite-public-main-container-feature-list-item"
            v-for="featureItem in feature.list"
            :key="featureItem.name"
          >
            <div
              class="zdd-invite-public-main-container-feature-list-item-name"
            >
              {{ featureItem.name }}
            </div>
            <div
              class="zdd-invite-public-main-container-feature-list-item-body"
            >
              <div
                class="zdd-invite-public-main-container-feature-list-item-desc"
                v-html="featureItem.desc"
              ></div>
              <img :src="featureItem.img" alt="" />
            </div>
          </div>
        </div>
      </section>
      <div class="zdd-invite-public-main-desc black">
        <p class="zdd-invite-public-main-desc-item">
          使用职袋袋，企业可以更方便地识别人才降低用人成本，也可以更轻松地激励优秀员工，塑造更加积极有活力的团队氛围。
        </p>
        <p class="zdd-invite-public-main-desc-item">
          职袋袋全新上线，新用户免费赠送20个证书，每邀请1家企业使用，还会分别赠送双方证书份数，欢迎体验。
        </p>
      </div>
    </div>
    <div class="zdd-invite-public-footer">
      <van-button type="primary" @click="handleToLogin"
        >免费试用 领取20个证书</van-button
      >
    </div>
  </div>
</template>

<script>
  import zddInviteJx1 from '@/assets/imgs/zdd/zdd_invite_jx_1.png'
  import zddInviteJx2 from '@/assets/imgs/zdd/zdd_invite_jx_2.png'
  import zddInviteJx3 from '@/assets/imgs/zdd/zdd_invite_jx_3.png'
  import zddInviteTx1 from '@/assets/imgs/zdd/zdd_invite_tx_1.png'
  import zddInviteTx2 from '@/assets/imgs/zdd/zdd_invite_tx_2.png'
  import zddInviteTx3 from '@/assets/imgs/zdd/zdd_invite_tx_3.png'
  import zddInviteLz1 from '@/assets/imgs/zdd/zdd_invite_lz_1.png'
  import zddInviteLz2 from '@/assets/imgs/zdd/zdd_invite_lz_2.png'
  import zddInviteLz3 from '@/assets/imgs/zdd/zdd_invite_lz_3.png'
  import zddInviteFeature1 from '@/assets/imgs/zdd/zdd_invite_feature_1.png'
  import zddInviteFeature2 from '@/assets/imgs/zdd/zdd_invite_feature_2.png'
  import zddInviteFeature3 from '@/assets/imgs/zdd/zdd_invite_feature_3.png'

  export default {
    name: 'ZddInvitePublic',
    data() {
      this.containerOptions1 = [
        {
          title: '职业档案',
          subTitle: '',
          content:
            '包含了一个人职业生涯的全过程,是每一个职场人士证明自身能力的强有力方式。但目前大多数职场人的工作表现都没有被记录到档案里,导致很多勤勤恳恳为公司做了大量切实贡献的人，却因为不善表达等因素，自身能力无法被识别，职业发展受到严重制约。',
        },
        {
          title: '职袋袋',
          subTitle: '就是人人信基于以上问题推出的一款职场档案类产品',
          content:
            '我们希望通过企业为优秀员工颁发证书和查询档案的功能，记录员工真实具体的工作表现，补充职场档案数据，从而帮助企业识别和激励优秀人才，让真正有能力的人发展越来越好，助力构建信任高效的职场环境。',
        },
        {
          title: '高光证书',
          subTitle: '是我们倡导的简单有效的档案记录方式',
          content:
            '在日常管理中，定期为优秀员工颁发高光证书，既操作简单又仪式感满满，见证员工的一路成长，是更具有长期价值的奖励方式。企业也可以更轻松地识别和激励优秀员工，塑造更加积极有活力的团队氛围。',
        },
      ]

      this.containerOptions2 = [
        {
          order: 1,
          title: '绩效考核场景',
          img1: zddInviteJx1,
          img2: zddInviteJx2,
          img3: zddInviteJx3,
        },
        {
          order: 2,
          title: '调岗调薪场景',
          img1: zddInviteTx1,
          img2: zddInviteTx2,
          img3: zddInviteTx3,
        },
        {
          order: 3,
          title: '离职场景',
          img1: zddInviteLz1,
          img2: zddInviteLz2,
          img3: zddInviteLz3,
        },
      ]

      this.feature = {
        title: '如何使用职袋袋？',
        desc:
          '职袋袋贯穿员工从入职到离职的整个周期，支持电脑端和手机端，简单易上手。',
        list: [
          {
            name: '1.公司招聘时，查询档案',
            desc: `<p>通过手机号查询或者应聘者主动授权查看 </p> <p>用事实而非天花乱坠的简历说话</p> `,
            img: zddInviteFeature1,
          },
          {
            name: '2.新人入职后，建立档案',
            desc: `<p>添加员工到企业通讯录后，自动建立档案</p> <p>支持单个添加、批量上传表格添加员工</p>`,
            img: zddInviteFeature2,
          },
          {
            name: '3.日常管理中，更新档案',
            desc: `<p>颁发证书，自动同步到档案</p> <p>支持单人颁发、批量颁发</p> `,
            img: zddInviteFeature3,
          },
        ],
      }
      return {
        comId: undefined,
      }
    },
    computed: {},
    created() {
      this.comId = this.$route.query.comId
      if (this.comId) {
        window.sessionStorage.setItem('inviteComId', this.comId)
      }
    },
    methods: {
      handleToLogin() {
        this.$router.replace({
          name: 'Login',
          query: {
            from: 'invite',
            comId: this.comId,
          },
        })
      },
    },
  }
</script>

<style scoped lang="less">
  .zdd-invite-public {
    background-color: #e6efff;
    padding-bottom: 64px;
    .zdd-invite-public-header-bg {
      width: 100%;
      display: flex;
      justify-content: center;
      transform: translateZ(-1px);
      img {
        margin: 0 auto;
        width: 368px;
        height: 100%;
      }
    }
    .zdd-invite-public-main {
      padding: 0 16px;
      margin-top: -40px;
      transform: translateZ(1px);
      .zdd-invite-public-main-container {
        width: 100%;
        box-sizing: border-box;
        background-color: @WHITE;
        padding: 16px 11px 20px 14px;
        border-radius: 4px;

        &.step {
          .zdd-invite-public-main-container-title {
            .zdd-invite-public-main-container-title-order {
              font-size: 23px;
              font-weight: 500;
              color: @blue;
              line-height: 26px;
              vertical-align: bottom;
            }
            .zdd-invite-public-main-container-title-text {
              margin-left: 6px;
              font-size: 15px;
              font-weight: 500;
              color: #111a34;
              line-height: 21px;
              vertical-align: bottom;
            }
          }
          .zdd-invite-public-main-container-cert-list {
            margin-top: 7px;
            display: flex;
            justify-content: space-between;
            img {
              width: 156px;
              height: 202px;
            }
          }
          .zdd-invite-public-main-container-cert-desc {
            margin-top: 5px;
            img {
              width: 100%;
            }
          }
        }
        &.feature {
          .zdd-invite-public-main-container-title {
            .zdd-invite-public-main-container-title-main {
              display: flex;
              align-items: center;
              font-size: 15px;
              font-weight: 500;
              color: @blue;
              line-height: 21px;
              &:before {
                content: '';
                width: 4px;
                height: 4px;
                background: @blue;
                border-radius: 50%;
                margin-right: 6px;
              }
            }
            .zdd-invite-public-main-container-title-desc {
              padding-left: 10px;
              font-size: 13px;
              font-weight: 400;
              color: #111a34;
              line-height: 18px;
              letter-spacing: 1px;
              margin-top: 8px;
            }
          }
          .zdd-invite-public-main-container-feature-list {
            margin-top: 14px;
            padding-left: 10px;
            font-size: 13px;
            .zdd-invite-public-main-container-feature-list-item {
              .zdd-invite-public-main-container-feature-list-item-name {
              }
              .zdd-invite-public-main-container-feature-list-item-body {
                padding-left: 9px;
                margin-top: 6px;
                .zdd-invite-public-main-container-feature-list-item-desc {
                  line-height: 18px;
                }
                img {
                  margin-top: 8px;
                  width: 297px;
                  height: 147px;
                }
              }
            }
            .zdd-invite-public-main-container-feature-list-item
              + .zdd-invite-public-main-container-feature-list-item {
              margin-top: 23px;
            }
          }
        }

        .zdd-invite-public-main-container-item {
          display: flex;
          flex-direction: column;
          .zdd-invite-public-main-container-item-title {
            display: flex;
            flex-direction: column;
            .zdd-invite-public-main-container-item-title-main {
              display: flex;
              align-items: center;
              color: #1676ff;
              font-size: 15px;
              font-weight: 500;
              line-height: 21px;
              &::before {
                content: '';
                width: 4px;
                height: 4px;
                border-radius: 50%;
                background-color: #1676ff;
                margin-right: 6px;
              }
            }
            .zdd-invite-public-main-container-item-title-sub {
              margin-top: 4px;
              padding-left: 10px;
              font-size: 13px;
              font-weight: 400;
              color: #1676ff;
              line-height: 18px;
            }
          }
          .zdd-invite-public-main-container-item-body {
            margin-top: 8px;
            padding-left: 10px;
            font-size: 13px;
            font-weight: 400;
            color: #111a34;
            line-height: 18px;
            letter-spacing: 1px;
          }
        }
        .zdd-invite-public-main-container-item
          + .zdd-invite-public-main-container-item {
          margin-top: 24px;
        }
      }
      .zdd-invite-public-main-container.step
        + .zdd-invite-public-main-container.step {
        margin-top: 24px;
      }
      .zdd-invite-public-main-desc {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        font-size: 13px;
        font-weight: 400;
        text-align: left;
        line-height: 18px;
        letter-spacing: 1px;
        &.blue {
          color: @blue;
          align-items: center;
          margin: 24px 0 12px;
        }
        &.black {
          color: #111a34;
          margin: 12px 0 24px;
        }
        .zdd-invite-public-main-desc-item + .zdd-invite-public-main-desc-item {
          margin-top: 9px;
        }
      }
    }
    .zdd-invite-public-footer {
      // 调整 z 轴
      transform: translateZ(2px);
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100%;
      .van-button {
        width: 100%;
        font-weight: 500;
        border-radius: 0;
        height: 50px;
        font-size: 16px;
      }
    }
  }
</style>
